import React, { useMemo } from 'react';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, Redirect, Route, Switch } from 'react-router-dom';

import { useAppSelector } from '@/hooks';
import { getPrefixedRoutes } from '@/selectors/appSelectors';

import Footer from '@/components/Footer/Footer';
import Header from '@/components/header/Header';
import Container from '@/components/container/Container';
import LuminorLoanFeedbackForm from '@/components/finance/Feedback/LuminorLoanFeedbackForm';
import LuminorLoanFeedbackComplete from '@/components/finance/Feedback/LuminorLoanFeedbackComplete';

type Props = RouteComponentProps;

function LoanFeedback({ location, match, history }: Props): React.ReactElement {
  const [t] = useTranslation('financeCenter');
  const routes = useAppSelector(getPrefixedRoutes);

  const parsedQuery = useMemo(() => parse(location.search), [location.search]);

  return (
    <>
      <Header t={t} />
      <Container center>
        <div className="fc-feedback">
          <h1 className="fc-feedback__title">{t('luminor.feedback.title')}</h1>
          <Switch>
            <Route path={`${match.path}/complete`} component={LuminorLoanFeedbackComplete} />
            <Route
              path={match.path}
              exact
              render={() => {
                if (!parsedQuery.hash) {
                  return <Redirect to={routes.root} />;
                }
                return (
                  <LuminorLoanFeedbackForm
                    initialRating={Number(parsedQuery.rating as string)}
                    hash={parsedQuery.hash as string}
                    onComplete={() => {
                      history.push(`${match.url}/complete`);
                    }}
                  />
                );
              }}
            />
          </Switch>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default LoanFeedback;
