import React from 'react';

import SuccessIcon from '@/img/icons/success.svg';
import Section from '@/components/section/Section';
import { useTranslation } from 'react-i18next';

type Props = unknown;

function LuminorLoanFeedbackComplete(props: Props): React.ReactElement {
  const [t] = useTranslation('financeCenter');
  return (
    <Section className="fc-feedback__complete">
      <img src={SuccessIcon} alt="success" />
      <div className="fc-feedback__complete-text">
        <h2 className="fc-feedback__complete-title">{t('luminor.feedback.complete.title')}</h2>
        <p className="fc-feedback__complete-subtitle">{t('luminor.feedback.complete.subtitle')}</p>
      </div>
    </Section>
  );
}

export default LuminorLoanFeedbackComplete;
