import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import api from '@/api/City24Api';
import { useAppSelector } from '@/hooks';
import { createRange } from '@/utils/collections';
import { getBrowser } from '@/selectors/appSelectors';

import Button from '@/components/button/Button';
import Icon from '@/components/icon/Icon';
import Container from '@/components/container/Container';
import ButtonGroup from '@/components/button/ButtonGroup';
import TextArea from '@/components/textArea/TextArea';

type Props = {
  initialRating?: number;
  hash: string;
  onComplete: () => void;
};

function LuminorLoanFeedbackForm({ initialRating, hash, onComplete }: Props): React.ReactElement {
  const [t] = useTranslation('financeCenter');
  const browser = useAppSelector(getBrowser);
  const [selectedRating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  useEffect(() => {
    const rating = Number(initialRating);
    if (rating) {
      setRating(rating);
    }
  }, []);

  const onRatingButtonClick = useCallback(
    (event) => {
      const { value } = event.currentTarget;
      setRating(Number(value));
    },
    [setRating]
  );

  const onSubmit = useCallback(() => {
    api.sendLuminorLoanFeedback({ secret_string: hash, rating: selectedRating, comment }).then(onComplete);
  }, [hash, selectedRating, comment]);

  return (
    <>
      <p className="fc-feedback__content">
        <Trans t={t} i18nKey="luminor.feedback.content" />
      </p>
      <Container className="fc-feedback__rating-block">
        <h4 className="fc-feedback__rating-header center">{t('luminor.feedback.ratingHeader')}</h4>

        <ButtonGroup center tight vertical={browser.lessThan.medium}>
          {createRange(5, 1, -1).map((nr) => {
            const selected = nr === selectedRating;
            return (
              <button
                type="button"
                className={`btn ${selected ? 'btn--primary' : 'btn--outline'}`}
                value={nr}
                onClick={onRatingButtonClick}
              >
                {nr} <Icon name={selected ? 'star' : 'star-o'} />
                <span className="fc-feedback__desc-text">{t(`luminor.feedback.rating.${nr}star`)}</span>
              </button>
            );
          })}
        </ButtonGroup>
        <br />
        <p>{t('luminor.feedback.commentTitle')}</p>
        <TextArea value={comment} getValue={setComment} />
      </Container>
      <div className="fc-feedback__bottom center">
        <Button primary onClick={onSubmit} disabled={selectedRating === 0}>
          {t('luminor.feedback.sendFeedback')}
        </Button>
      </div>
    </>
  );
}

export default LuminorLoanFeedbackForm;
